import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    download: false,
    urlKey: '',
    extension: 'svg',
    name: '',
    options: {
        style: 'square',
        colour: '#000000',
        logo: false,
    },
}

export const qrCodeSlice = createSlice({
    name: 'qrCode',
    initialState,
    reducers: {
        resetQrCode: (state) => initialState,
        setQrOptions: (state, action) => {
            state.options = action.payload
        },
        triggerDownload: (state, action) => {
            const data = JSON.parse(action.payload)
            state.urlKey = data.urlKey
            state.download = data.download
            state.name = data.name
        },
        setExt: (state, action) => {
            state.extension = action.payload
        },
        setStyle: (state, action) => {
            state.options.style = action.payload
        },
        setColour: (state, action) => {
            state.options.colour = action.payload
        },
        setStyling: (state, action) => {
            state.options.styling = action.payload
        },
        setLogo: (state, action) => {
            state.options.logo = action.payload
        },
    },
})

// Action creators are generated for each case reducer function
export const {
    resetQrCode,
    setQrOptions,
    triggerDownload,
    setExt,
    setStyle,
    setColour,
    setStyling,
    setLogo,
} = qrCodeSlice.actions

export default qrCodeSlice.reducer