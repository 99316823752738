import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    isAddNew: false,
    isEdit: false,
    baseUrl: 'https://b-arrows',
    campaign: 'Barrows | B!ink Management Platform'
}

export const globalsSlice = createSlice({
    name: 'globals',
    initialState,
    reducers: {
        globalsState: (state, action) => {
            // Redux Toolkit allows us to write "mutating" logic in reducers. It
            // doesn't actually mutate the state because it uses the Immer library,
            // which detects changes to a "draft state" and produces a brand new
            // immutable state based off those changes    
            const location = JSON.parse(action.payload)
            state.baseUrl = location.origin
        },
        setIsAddNew: (state, action) => {
            state.isAddNew = JSON.parse(action.payload)
        },
        setIsEdit: (state, action) => {
            state.isEdit = action.payload
        }
    },
})

// Action creators are generated for each case reducer function
export const {
    globalsState,
    setIsAddNew,
    setIsEdit,
} = globalsSlice.actions

export default globalsSlice.reducer