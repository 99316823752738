// https://mui.com/customization/palette/

const palette = {
    background: {
        default: '#FFFFFF'
    },
    black: {
        main: '#000000',
        off: '#0F0F0F'
    },
    white: {
        main: '#FFFFFF'
    },
    grey: {
        main: '#E0E0E0',
        darkLite: '#B6B6B6',
        dark: '#616161',
        lite: '#B6B6B6',
        lighter: '#DBDBDB',
        lightest: '#f5f8fa',
    },
    blue: {
        main: '#1dc8fd',
        dark: '#3391d8',
        lighter: '#ebfaff',
    },
    red: {
        main: '#FF0000',
    },
    green: {
        main: '#9AC953',
    },
    gradient: {
        main: 'linear-gradient(90deg, #AD00FF 0%, #2400FF 118.18%)',
        reversed: 'linear-gradient(90deg, #2400FF 0%, #AD00FF 118.18%)',
        start: '#AD00FF',
        end: '#2400FF',
    }
}

export default palette