// https://mui.com/customization/breakpoints/

const breakpoints = {
    values: {
        xxxs: 0,
        xxs: 320,
        xs: 480,
        sm: 576,
        md: 768,
        lg: 992,
        xl: 1200,
        xxl: 1400,
        mobile: 0,
        tablet: 640,
        laptop: 1024,
        desktop: 1200,
    },
}

export default breakpoints