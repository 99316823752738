// https://mui.com/customization/theme-components/
import { keyframes } from '@mui/system'

const testHover = keyframes`
  0%, 100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
`;


const components = {
    MuiButton: {
        variants: [
            {
                props: { variant: 'primary' },
                style: ({ ownerState, theme }) => ({
                    alignItems: 'center',
                    background: 'linear-gradient(90deg, #AD00FF 0%, #2400FF 118.18%)',
                    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.3)',
                    borderRadius: '4px',
                    // backgroundColor: theme.palette.white.main,
                    // borderRadius: 4,
                    color: theme.palette.black.main,
                    cursor: 'pointer',
                    fontSize: '16px',
                    padding: '10px 0',
                    width: '100%',
                    transition: 'backgroundColor .1s',
                    ':before, :after': {
                        position: 'absolute',
                        content: '""',
                        height: '100%',
                        width: '100%',
                        zIndex: '1',
                    },
                    ':before': {
                        background: 'linear-gradient(90deg, rgba(173, 0, 255, 0.9) 0%, rgba(36, 0, 255, 0.5) 118.18%)',
                        opacity: 0
                    },
                    ':after': {
                        background: 'linear-gradient(45deg, rgba(36, 0, 255, 0.5) 118.18%, rgba(173, 0, 255, 0.9) 0%)',
                        opacity: 0
                    },
                    ':hover': {
                        transition: 'backgroundColor 2s 1s',
                        ':before, :after': {
                            animationName: testHover,
                            animationDuration: '3s',
                            animationIterationCount: 'infinite',
                            animationFillMode: 'backwards',
                        },
                        ':before': {
                            animationDelay: '0s'
                        },
                        ':after': {
                            animationDelay: '2s'
                        }
                    }
                }),
            },
            {
                props: { variant: 'secondary' },
                style: ({ ownerState, theme }) => ({
                    alignItems: 'center',
                    backgroundColor: theme.palette.grey.main,
                    borderRadius: 6,
                    color: theme.palette.grey.dark,
                    cursor: 'pointer',
                    fontFamily: 'IBM Plex sans',
                    fontWeight: 400,
                    fontSize: '14px',
                    padding: '10px 3px',
                    textTransform: 'none',
                    width: '100%',
                    ':hover': {
                        backgroundColor: theme.palette.black.main,
                        color: theme.palette.white.main,
                    }
                }),
            },
            {
                props: { variant: 'submit' },
                style: ({ ownerState, theme }) => ({
                    backgroundColor: theme.palette.black.main,
                    color: theme.palette.white.main,
                    cursor: 'pointer',
                    fontSize: '16px',
                    fontFamily: 'IBM Plex sans',
                    fontWeight: 400,
                    padding: '0px',
                    margin: '0px',
                    lineHeight: '0px',
                    verticalAlign: 'baseline',
                    textTransform: 'none',
                    width: '100%',
                }),
            },
        ]
    }
}

export default components
