import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    isOpen: false,
    item: '',
}

export const globalsSlice = createSlice({
    name: 'modal',
    initialState,
    reducers: {
        openDeleteModal: (state, action) => {
            const item = action.payload
            state.isOpen = true
            state.item = item
        },
        closeModal: (state) => initialState,
    },
})

// Action creators are generated for each case reducer function
export const {
    openDeleteModal,
    closeModal,
} = globalsSlice.actions

export default globalsSlice.reducer